import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuchikomyuSettingByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingByRestaurantId";
import { Box, CardBody, CardFooter, CardHeader, Divider } from "@chakra-ui/react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { AlertMsg } from "../../molecules/AlertMsg";
import { SettingItem } from "../../organisms/SettingItem";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const KuchikomyuSettingForRestaurant = () => {
  const { restaurantId } = useParams()
  const {kuchikomyuSetting, isLoading} = useFetchKuchikomyuSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (!kuchikomyuSetting) return <AlertMsg msg="設定が完了してません。営業担当に連絡してください。"/>
  const qrCardImg = kuchikomyuSetting.qr_card_img ?? '/images/kuchikomyu.png'
  const enQrCardImg = kuchikomyuSetting.en_qr_card_img ?? '/images/kuchikomyu-en.png'

  console.log(kuchikomyuSetting);

  return (
    <Box m='auto' w={{base: '100%', md: '700px'}} fontSize='sm'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='口コミュ設定'/>
        </CardHeader>
        <CardBody py={2}>
          <Box fontWeight='bold'>
            <SettingItem label='ブランチスコア' content={kuchikomyuSetting.branch_score} />
            <Divider />
            <SettingItem
                label='独自アンケート'
                content={kuchikomyuSetting.unique_question ? '使用しない' : '使用する'}
            />
            <Divider />
            <SettingItem
              label='QRカード'
              content={
                <Box
                  bgImage={`url(${qrCardImg})`}
                  w="203px"
                  h="287px"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  border='0.1px solid #c0c0c09d'
                />
              }
            />
            <Divider />
            <SettingItem
              label='英語版QRカード'
              content={
                <Box
                  bgImage={`url(${enQrCardImg})`}
                  w="203px"
                  h="287px"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  border='0.1px solid #c0c0c09d'
                />
              }
            />
          </Box>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};