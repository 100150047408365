import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { switchQrCardImgAppend } from "../../utils/switchQrCardImgAppend";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useEditKuchikomyuSettingByRestaurant = (settingsId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("branch_score", data.branch_score);
      uploadData.append("unique_question", data.unique_question ? "true" : "false");
      switchQrCardImgAppend(data.qr_card_img, uploadData);
      switchEnQrCardImgAppend(data.en_qr_card_img, uploadData);
      await customAuthAxios.put(`kuchikomyu/settings/${settingsId}/`, uploadData)
      showToast('編集が完了しました')
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};

const switchEnQrCardImgAppend = (qrCardImg: any, uploadData: FormData) => {
  if (qrCardImg == 'default') return uploadData.append('en_qr_card_img', '');
  if (qrCardImg[0]) return uploadData.append("en_qr_card_img", qrCardImg[0], qrCardImg[0].name);
}