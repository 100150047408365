import { useSWRConfig } from "swr";
import { customAuthAxios } from "../utils/customAuthAxios";
import { useSuccessToast } from "./toast/useSuccessToast";


export const useDeleteWithMutateKey = (path: string, onClose: () => void, mutateKey: string) => {
  const { mutate } = useSWRConfig()
  const showToast = useSuccessToast();

  const onSubmit = async () => {
    try {
      await customAuthAxios.delete(path)
      mutate(mutateKey)
      onClose()
      showToast('削除が完了しました')
    } catch (error: any) {
    }
  };

  return onSubmit;
};