import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useEffect} from "react";


const editManlaboSettingByRestaurantSchema = yup.object().shape({
    branch_score: yup.number().required('入力必須項目です'),
    branch_question: yup.string().required('入力必須項目です'),
    qr_card_img: yup.mixed(),
    is_navigate_g_url: yup.boolean(),
    unique_question: yup.boolean().optional(),
});
type ManLaboSetting = {
    id: string;
    restaurant: string;
    branch_score: number;
    g_url: string;
    is_navigate_g_url: boolean;
    branch_question: string;
    branch_question_name: string;
    unique_question: boolean;
}
export const useEditManlaboSettingFormForRestaurant = (manLaboSetting?: ManLaboSetting) => {
    const {
        handleSubmit,
        register,
        control,
        formState: {errors, isSubmitting},
        setValue
    } = useForm({
        resolver: yupResolver(editManlaboSettingByRestaurantSchema),
        defaultValues: {
            branch_score: manLaboSetting?.branch_score ?? 0,
            unique_question: manLaboSetting?.unique_question ?? false,
            is_navigate_g_url: manLaboSetting?.is_navigate_g_url ?? false,
            branch_question: manLaboSetting?.branch_question ?? '',
        },
    });
    useEffect(() => {
        if (manLaboSetting) {
            setValue('branch_score', manLaboSetting.branch_score);
            setValue('branch_question', manLaboSetting.branch_question);
            setValue('is_navigate_g_url', manLaboSetting.is_navigate_g_url);
            setValue('unique_question', manLaboSetting.unique_question);
        }
    }, [manLaboSetting]);
    return {
        handleSubmit,
        register,
        control,
        isSubmitting,
        errors,
        setValue
    };
};