import React, { memo } from "react";
import {
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/react';
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";

export const InstagramAccordionMenu: React.FC = memo(() => {
    return (
        <AccordionItem border='none'>
            <AccordionButton>
                <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
                    Instagramマネージャー
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
                <AccordionList>
                    <AccordionItemLink to={`instagram/view`} text="投稿管理" />
                    <AccordionItemLink to={`instagram/contents`} text="コンテンツ一覧" />
                    <AccordionItemLink to={`instagram/settings`} text="設定" />
                </AccordionList>
            </AccordionPanel>
        </AccordionItem>
    );
});