import useSWR from "swr";

type KuchikomyuSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  en_url: string;
  qr_card_img?: string;
  en_qr_card_img?: string;
  use_custom_survey?: boolean;
  unique_question: boolean;
}

export const useFetchKuchikomyuSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `kuchikomyu/restaurants/${restaurantId}/setting/` : null;
  const { data: kuchikomyuSetting, error, isLoading } = useSWR<KuchikomyuSetting>(fetcherKey)
  return { kuchikomyuSetting, isLoading, error };
};