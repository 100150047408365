import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { switchQrCardImgAppend } from "../../utils/switchQrCardImgAppend";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useEditManLaboSettingByRestaurant = (settingsId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("branch_score", data.branch_score);
      uploadData.append("branch_question", data.branch_question);
      uploadData.append("is_navigate_g_url", data.is_navigate_g_url);
      uploadData.append("unique_question", data.unique_question ? "true" : "false");
      switchQrCardImgAppend(data.qr_card_img, uploadData);
      await customAuthAxios.put(`manzokudo_labo/settings/${settingsId}/`, uploadData)
      showToast('編集が完了しました')
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};