import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";

const kuchikomyuSettingEditByRestaurantSchema = yup.object({
    id: yup.string().required(), // ID を追加
    restaurant: yup.string().required(), // restaurant を追加
    branch_score: yup.number().required('ブランチスコアを入力してください'),
    unique_question: yup.boolean().optional(),
    g_url: yup.string().url('正しいURLを入力してください').required('Google URLを入力してください'),
    en_url: yup.string().url('正しいURLを入力してください').nullable(),
    qr_card_img: yup.mixed().optional(),
    en_qr_card_img: yup.mixed().optional(),
});

type KuchikomyuSetting = {
    id: string;
    restaurant: string;
    branch_score: number;
    unique_question: boolean;
    g_url: string;
    en_url: string;
};

export const useEditKuchikomyuSettingFormForRestaurant = (kuchikomyuSetting?: KuchikomyuSetting) => {
    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isSubmitting },
        setValue,
    } = useForm<KuchikomyuSetting>({
        resolver: yupResolver(kuchikomyuSettingEditByRestaurantSchema) as any, // 型のミスマッチを解消
        defaultValues: {
            id: kuchikomyuSetting?.id ?? '',
            restaurant: kuchikomyuSetting?.restaurant ?? '',
            branch_score: kuchikomyuSetting?.branch_score ?? 0,
            unique_question: kuchikomyuSetting?.unique_question ?? false,
            g_url: kuchikomyuSetting?.g_url ?? '',
            en_url: kuchikomyuSetting?.en_url ?? '',
        },
    });

    useEffect(() => {
        if (kuchikomyuSetting) {
            setValue('branch_score', kuchikomyuSetting.branch_score);
            setValue('unique_question', kuchikomyuSetting.unique_question);
            setValue('g_url', kuchikomyuSetting.g_url);
            setValue('en_url', kuchikomyuSetting.en_url);
        }
    }, [kuchikomyuSetting, setValue]);

    return {
        handleSubmit,
        control,
        register,
        isSubmitting,
        errors,
        setValue,
    };
};