import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    Box,
    Button,
    VStack,
    Text,
    Input,
    HStack,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    Spinner
} from "@chakra-ui/react";
import {FaUsers, FaImages, FaEye, FaHeart, FaComment, FaShare, FaInstagram} from "react-icons/fa";
import {useParams} from "react-router-dom";
import StatCard from "../../organisms/instagram/StatCard";
import {
    initializeFacebookSDK,
    facebookLogin,
    obtainLongLivedToken
} from "../../../utils/instagram/services/facebookService";
import {
    fetchProfileMetrics,
    fetchMediaData,
    saveAuthData,
    getDjangoToken
} from "../../../utils/instagram/services/apiService";
import {Line, Bar} from 'react-chartjs-2';
import {Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, TimeScale,BarElement} from 'chart.js';
import {customAuthAxios} from "../../../utils/customAuthAxios";

;

// `TimeScale` を含めて登録
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, TimeScale,BarElement);

const truncateCaption = (caption: string, maxLength: number = 50) => {
    return caption.length > maxLength ? caption.slice(0, maxLength) + "..." : caption;
};

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString("ja-JP", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

const Instagram: React.FC = () => {
    const {restaurantId} = useParams<{ restaurantId: string }>();
    const [activeTab, setActiveTab] = useState<'all' | 'posts' | 'stories'>('all'); // ここに移動
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [businessAccountId, setBusinessAccountId] = useState<string>("");
    const [mediaData, setMediaData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [metrics, setMetrics] = useState<{
        followerCount: number;
        followingCount: number;
        postCount: number;
        totalLikes: number;
        totalComments: number;
        totalSaved: number;
        totalPlays: number;
    } | null>(null);

    const [contents, setContents] = useState<{
        interactionPeriod: number;
        interactionMedia: number;
        reachCount: number;
    } | null>(null);

    const [graphData, setGraphData] = useState<{
        labels: string[],
        reachData: number[],
        impressionsData: number[],
        followerData: number[]
    }>({ labels: [], reachData: [], impressionsData: [], followerData: [] });

    const [displayMode, setDisplayMode] = useState<'images' | 'videos'>('images');

    // フィルタリングと並び替えロジック
    const sortedMediaData = mediaData
        .filter((post) => {
            if (displayMode === 'images') return post.media_type === 'IMAGE';
            if (displayMode === 'videos') return post.media_type === 'VIDEO';
            return false; // 万が一 displayMode が無効な場合
        })
        .sort((a, b) => (b.impressions || 0) - (a.impressions || 0)); // インプレッション数の降順に並び替え

    useEffect(() => {
        const fetchInitialInstagramData = async () => {
            setIsLoading(true);

            try {
                const djangoToken = await getDjangoToken();
                if (!djangoToken) {
                    console.error("システムからトークンが取得できませんでした。");
                    setIsLoading(false);
                    return;
                }

                const response = await customAuthAxios.get(`external_api/instagram-auth/${restaurantId}/`);

                const {token, instagram_id} = response.data;

                setAccessToken(token);
                setBusinessAccountId(instagram_id);

                await initializeFacebookSDK();
                const media = await fetchInstagramData(token, instagram_id);
                if (media && media.length > 0) {
                    setIsAuthenticated(true);
                    setMediaData(media);
                    fetchMetricsData(token, instagram_id);
                } else {
                    //setIsAuthenticated(false);
                    console.log("メディアが取得できませんでした。");
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    console.log("データが見つかりませんでした。ログインが必要です。");
                    setIsLoading(false);
                    setIsAuthenticated(false); // ログイン画面に誘導
                } else {
                    setIsLoading(false);
                    setIsAuthenticated(false);
                }
            } finally {
                setIsLoading(false);
            }
        };

        const fetchMetricsData = async (token: string, instagramId: string) => {
            try {
                const djangoToken = await getDjangoToken();
                if (!djangoToken) return;

                const response = await customAuthAxios.get(`external_api/instagram-auth/${restaurantId}/`, {
                    headers: {
                        Authorization: `JWT ${djangoToken}`,
                    },
                });

                // 各メトリクスに対応するデータを設定
                const labels = response.data.metrics.map((item: any) => formatDate(item.timestamp));
                const reachData = response.data.metrics.map((item: any) => item.total_reach); // リーチ
                const impressionsData = response.data.metrics.map((item: any) => item.total_impressions); // インプレッション
                const followerData = response.data.metrics.map((item: any) => item.follower_count); // フォロワー数

                setGraphData({labels, reachData, impressionsData, followerData});

                // 他のプロファイルメトリクスも更新
                const profileMetrics = await fetchProfileMetrics(token, instagramId);
                setMetrics((prevMetrics) => ({
                    followerCount: profileMetrics.followerCount,
                    followingCount: profileMetrics.followingCount,
                    postCount: mediaData.length,
                    totalLikes: prevMetrics?.totalLikes || 0,
                    totalComments: prevMetrics?.totalComments || 0,
                    totalSaved: prevMetrics?.totalSaved || 0,
                    totalPlays: prevMetrics?.totalPlays || 0,
                }));

                setIsAuthenticated(true);

            } catch (error) {
                console.error("Error fetching metrics data:", error);
            }
        };

        fetchInitialInstagramData();

        if (!isAuthenticated) {
            setIsLoading(false);
        }
        console.log(mediaData);
    }, [mediaData.length, restaurantId]);

    const fetchInstagramData = async (token?: string, instagramId?: string) => {
        const currentToken = token || accessToken;
        const currentInstagramId = instagramId || businessAccountId;
        if (!currentToken || !currentInstagramId) return null;

        try {
            const profileMetrics = await fetchProfileMetrics(currentToken, currentInstagramId);
            const media = await fetchMediaData(currentToken, currentInstagramId);

            const totalLikes = media.reduce((sum, post) => sum + (post.likes || 0), 0);
            const totalComments = media.reduce((sum, post) => sum + (post.comments || 0), 0);
            const totalSaved = media.reduce((sum, post) => sum + (post.saved || 0), 0);
            const totalPlays = media.reduce((sum, post) => sum + (post.plays || 0), 0);

            setMetrics({
                ...profileMetrics,
                postCount: media.length,
                totalLikes,
                totalComments,
                totalSaved,
                totalPlays,
            });
            setIsAuthenticated(true);
            setMediaData(media);

            const djangoToken = await getDjangoToken();
            if (djangoToken) {
                await saveAuthData(restaurantId!, currentToken, currentInstagramId, djangoToken);
            }
            return media;
        } catch (error) {
            console.error("Error fetching Instagram data:", error);
            return null;
        }
    };

    const handleLogin = async () => {
        try {
            await initializeFacebookSDK(); // SDKを確実に初期化
            const shortLivedToken = await facebookLogin();

            if (shortLivedToken) {
                const longLivedToken = await obtainLongLivedToken(shortLivedToken);
                if (longLivedToken) {
                    setAccessToken(longLivedToken);
                    setIsAuthenticated(true);
                } else {
                    console.log("Failed to obtain long-lived token.");
                }
            } else {
                console.log("Facebookログインが失敗しました.");
            }
        } catch (error) {
            console.error("Error initializing Facebook SDK or logging in:", error);
        }
    };

    // 各データセットのチャートデータとオプションを設定
    const reachChartData = {
        labels: graphData.labels,
        datasets: [
            {
                label: 'リーチの推移',
                data: graphData.reachData,
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
            },
        ],
    };

    const impressionsChartData = {
        labels: graphData.labels,
        datasets: [
            {
                label: 'インプレッションの推移',
                data: graphData.impressionsData,
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
            },
        ],
    };

    const followerChartData = {
        labels: graphData.labels,
        datasets: [
            {
                label: 'フォロワー数の推移',
                data: graphData.followerData,
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
            },
        ],
    };

    //グラフ表示
    const allChartData = {
        labels: graphData.labels,
        datasets: [
            {
                label: 'リーチ数',
                data: graphData.reachData,
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
            },
        ],
    };

    const postInteractionData = {
        labels: graphData.labels, // 月ごとのデータを用意
        datasets: [
            {
                label: 'インタラクション数',
                data: graphData.reachData, // 仮にリーチデータを使用
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    const mediaTypeInteractionData = {
        labels: ['写真', '動画', 'その他'],
        datasets: [
            {
                label: 'インタラクション数',
                data: [150, 300, 100], // 仮のデータ
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const storiesChartData = {
        labels: graphData.labels, // 月ごとのデータを使用
        datasets: [
            {
                label: 'リーチ数',
                data: graphData.reachData, // 仮にリーチデータを使用
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
            },
        ],
    };



// 各チャートオプション（同一のオプションを3つのグラフで使用）
    const chartOptions = {
        scales: {
            x: {
                reverse: true,
            },
        },
    } as const;

    const barChartOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'カテゴリ',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'インタラクション数',
                },
            },
        },
    };


    return (

        <Box>
            <style>{`p{margin-bottom:0;vertical-align:middle}`}</style>
            {isLoading ? (
                <VStack>
                    <Spinner size="xl" color="blue.500" />
                    <Text>データを取得しています...</Text>
                </VStack>
            ):(
            <VStack spacing={4} p={4} w="full">
                {!isAuthenticated && (
                    <Card>
                        <SimpleGrid columns={1} spacing={4} w="full">
                            <VStack spacing={1} display="flex" alignItems="left" w="full">
                                <CardHeader><HStack><FaInstagram/><Text
                                    fontWeight="bold">{`Instagramの設定が完了していません。\n`}</Text></HStack></CardHeader>
                            </VStack>
                            <VStack spacing={1} display="flex" alignItems="left" w="full">
                                <CardBody>
                                    <Text
                                        mb={2}>メニューのInstagramマネージャーの設定画面から、Facebookを使用した連携を行ってください。</Text>
                                    <Text
                                        mb={2}>FacebookログインからInstagramを連携するにはFacebookページの作成と、Instagramアカウントの連携が必要になります。</Text>
                                    <Text mb={2}>登録を行う前にあらかじめ設定を行ってください。</Text>
                                </CardBody>
                            </VStack>
                        </SimpleGrid>
                    </Card>
                )}

                {metrics && (
                    <Card w="full">
                        <CardBody w="full">
                            <SimpleGrid columns={[2, 4]} spacing={4} w="full">
                                <StatCard label="フォロワー数 (人)" value={metrics.followerCount} icon={FaUsers}/>
                                <StatCard label="フォロー数 (人)" value={metrics.followingCount} icon={FaUsers}/>
                                <StatCard label="投稿数 (件)" value={metrics.postCount} icon={FaImages}/>
                                <StatCard label="いいね数" value={metrics.totalLikes} icon={FaHeart}/>
                                <StatCard label="コメント数" value={metrics.totalComments} icon={FaComment}/>
                                <StatCard label="保存数" value={metrics.totalSaved} icon={FaShare}/>
                                <StatCard label="動画再生数" value={metrics.totalPlays} icon={FaEye}/>
                            </SimpleGrid>
                        </CardBody>
                    </Card>
                )}


                {metrics && (
                    <Card w="full">
                        <CardBody w="full">
                            <SimpleGrid columns={[2, 2]} spacing={4} mt={4} w="full">
                                <VStack w="full" mt={6} align="start">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>リーチの推移</Text>
                                    <Line key={`reach-${JSON.stringify(graphData.labels)}`} data={reachChartData}
                                          options={chartOptions}/>
                                </VStack>
                                <VStack w="full" mt={6} align="start">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>インプレッションの推移</Text>
                                    <Line key={`impressions-${JSON.stringify(graphData.labels)}`}
                                          data={impressionsChartData}
                                          options={chartOptions}/>
                                </VStack>
                                <VStack w="full" mt={6} align="start">
                                    <Text fontWeight="bold" fontSize="lg" mb={2}>フォロワー数の推移</Text>
                                    <Line key={`followers-${JSON.stringify(graphData.labels)}`} data={followerChartData}
                                          options={chartOptions}/>
                                </VStack>
                            </SimpleGrid>
                        </CardBody>
                    </Card>
                )}
                {metrics && (
                    <Card w="full">
                        <CardHeader w="full">
                            <HStack spacing={1} alignItems="center">
                                <FaInstagram/><Text fontWeight="bold">コンテンツの概要</Text>
                            </HStack>
                            <HStack mt={5}>
                                <Button
                                    onClick={() => setActiveTab('all')}
                                    colorScheme={activeTab === 'all' ? 'blue' : 'gray'}
                                >
                                    すべて
                                </Button>
                                <Button
                                    onClick={() => setActiveTab('posts')}
                                    colorScheme={activeTab === 'posts' ? 'blue' : 'gray'}
                                >
                                    投稿
                                </Button>
                                <Button
                                    onClick={() => setActiveTab('stories')}
                                    colorScheme={activeTab === 'stories' ? 'blue' : 'gray'}
                                >
                                    ストーリーズ
                                </Button>
                            </HStack>
                        </CardHeader>
                        <CardBody w="70%">
                            {activeTab === 'all' && (
                                <Line data={allChartData} options={chartOptions} />
                            )}
                            {activeTab === 'posts' && (
                                <SimpleGrid columns={2} spacing={4}>
                                    <VStack w="75%">
                                        <Text fontWeight="bold">期間別のインタラクション</Text>
                                        <Bar data={postInteractionData} options={barChartOptions} />
                                    </VStack>
                                    <VStack w="75%">
                                        <Text fontWeight="bold">メディアタイプ別のインタラクション</Text>
                                        <Bar data={mediaTypeInteractionData} options={barChartOptions} />
                                    </VStack>
                                </SimpleGrid>
                            )}
                            {activeTab === 'stories' && (
                                <Line data={storiesChartData} options={chartOptions} />
                            )}
                        </CardBody>
                    </Card>

                )}
                {mediaData.length > 0 && (
                    <Card w="full">
                        <CardHeader w="full">
                            <HStack spacing={1} alignItems="center">
                                <FaInstagram />
                                <Text fontWeight="bold">リーチ別のトップコンテンツ</Text>
                            </HStack>
                            {/* 表示切り替えボタン */}
                            <HStack mt={4}>
                                <Button
                                    onClick={() => setDisplayMode('images')}
                                    colorScheme={displayMode === 'images' ? 'blue' : 'gray'}
                                >
                                    画像
                                </Button>
                                <Button
                                    onClick={() => setDisplayMode('videos')}
                                    colorScheme={displayMode === 'videos' ? 'blue' : 'gray'}
                                >
                                    動画
                                </Button>
                            </HStack>
                        </CardHeader>
                        <CardBody w="full">
                            <Box w="full">
                                <SimpleGrid columns={[2, 3, 4, 5]} spacing={4}>
                                    {sortedMediaData.map((post) => (
                                        <Box key={post.id} p={4} bg="white" borderRadius="md" boxShadow="md">
                                            <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                                                {post.media_type === 'VIDEO' ? (
                                                    <video controls width="100%">
                                                        <source src={post.media_url} type="video/mp4" />
                                                        お使いのブラウザは video タグをサポートしていません。
                                                    </video>
                                                ) : (
                                                    <img src={post.media_url} alt={truncateCaption(post.caption)} width="100%" />
                                                )}
                                            </a>
                                            <Text mt={2}>{truncateCaption(post.caption)}</Text>
                                            <Text mt={1}>{formatDate(post.timestamp)}</Text>
                                            <HStack spacing={4} mt={2} alignItems="center">
                                                <HStack spacing={1} alignItems="center">
                                                    <FaEye />
                                                    <Text>{post.impressions || 0}</Text>
                                                </HStack>
                                                <HStack spacing={1} alignItems="center">
                                                    <FaHeart />
                                                    <Text>{post.likes || 0}</Text>
                                                </HStack>
                                                <HStack spacing={1} alignItems="center">
                                                    <FaComment />
                                                    <Text>{post.comments || 0}</Text>
                                                </HStack>
                                                <HStack spacing={1} alignItems="center">
                                                    <FaShare />
                                                    <Text>{post.saved || 0}</Text>
                                                </HStack>
                                            </HStack>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Box>
                        </CardBody>
                    </Card>
                )}
            </VStack>
            )}
        </Box>

    );
};

export default Instagram;