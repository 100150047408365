import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuchikomyuSettingCustomerByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingCustomerByRestaurantId";
import { Box } from "@chakra-ui/react";
import { useSubmitKuchikomyuCustomerOverallScoreEn } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuCustomerOverallScoreEn";
import { usePostKuchikomyuOverallScoreFormEn } from "../../../hooks/kuchikomyu/usePostKuchikomyuOverallScoreFormEn";
import { PostOverallScoreFormEn } from "../../molecules/form/PostOverallScoreFormEn";

export const PostKuchikomyuOverallScoreEn: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId } = params;
  const { kuchikomyuSetting, isLoading } = useFetchKuchikomyuSettingCustomerByRestaurantId(restaurantId);

  // 🔹 リダイレクト中のフラグ
  const [isRedirecting, setIsRedirecting] = useState(false);

  // unique_question が true なら en_url にリダイレクト
  useEffect(() => {
    if (kuchikomyuSetting?.unique_question && kuchikomyuSetting.en_url) {
      setIsRedirecting(true); // リダイレクト開始
      setTimeout(() => {
        window.location.href = kuchikomyuSetting.en_url;
      }, 500); // 500ms のディレイを挟んでリダイレクト
    }
  }, [kuchikomyuSetting]);

  // 🔹 ローディング中またはリダイレクト中ならローディング表示
  if (isLoading || isRedirecting) return <CircularLoading />;

  // データが取得できない場合は何も表示しない
  if (!kuchikomyuSetting) return null;

  return (
      <Box p={4}>
        <ScorePost
            restaurantId={restaurantId ?? ""}
            branchScore={kuchikomyuSetting.branch_score}
            enUrl={kuchikomyuSetting.en_url}
        />
      </Box>
  );
});

const ScorePost: React.FC<{ restaurantId: string; branchScore: number; enUrl: string }> = memo(({ restaurantId, branchScore, enUrl }) => {
  const { handleSubmit, control, isSubmitting, errors } = usePostKuchikomyuOverallScoreFormEn();
  const onSubmit = useSubmitKuchikomyuCustomerOverallScoreEn(restaurantId, branchScore, enUrl);

  return (
      <PostOverallScoreFormEn
          onSubmit={handleSubmit(onSubmit)}
          control={control}
          isSubmitting={isSubmitting}
          errors={errors}
      />
  );
});