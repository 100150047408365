import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Text,
    Image,
    VStack,
    HStack,
    Center,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    GridItem,
    Grid,
    Button
} from '@chakra-ui/react';
import { FaHeart, FaComment, FaEye, FaShare } from 'react-icons/fa';
import { customAuthAxios } from '../../../utils/customAuthAxios';
import { getDjangoToken } from '../../../utils/instagram/services/apiService';

const InstagramContentsDetail: React.FC = () => {
    const { restaurantId, contentId } = useParams<{ restaurantId: string; contentId: string }>();
    const [postDetails, setPostDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true); // ローディング状態

    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                setIsLoading(true); // ローディング開始
                const djangoToken = await getDjangoToken();
                const response = await customAuthAxios.get(
                    `/external_api/instagram-auth/metrics/${restaurantId}/${contentId}/`,
                    {
                        headers: { Authorization: `JWT ${djangoToken}` },
                    }
                );
                setPostDetails(response.data);
            } catch (error) {
                console.error('Failed to fetch post details:', error);
            } finally {
                setIsLoading(false); // ローディング終了
            }
        };

        fetchPostDetails();
    }, [restaurantId, contentId]);

    if (isLoading) {
        return (
            <Center height="200px">
                <Spinner size="xl" color="blue.500" />
            </Center>
        );
    }

    if (!postDetails) return <Text>データが見つかりません。</Text>;

    return (
        <Box p={4} width="75%">
            <Grid templateColumns="2fr 1fr" gap={4}>
                {/* 左側: 詳細情報 */}
                <GridItem>
                    <Card>
                        <CardHeader>
                            <Text fontWeight="bold" fontSize="lg">概要</Text>
                        </CardHeader>
                        <CardBody>
                            <VStack spacing={2} align="start">
                                <HStack justifyContent="space-between" width="75%">
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">リーチ数</Text>
                                        <Text fontWeight="bold" fontSize="xl">{postDetails.reach || 0}</Text>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="md">インプレッション</Text>
                                        <Text fontWeight="bold" fontSize="xl">{postDetails.impressions || 0}</Text>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">インタラクション</Text>
                                        <Text fontWeight="bold" fontSize="xl">
                                            {(postDetails.like_count || 0) +
                                                (postDetails.comments_count || 0) +
                                                (postDetails.saved || 0)}
                                        </Text>
                                    </Box>
                                </HStack>
                                <HStack justifyContent="space-between" width="100%" mt={6}>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">「いいね！」</Text>
                                        <Text fontWeight="bold" fontSize="xl">{postDetails.like_count || 0}</Text>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">コメント数</Text>
                                        <Text fontWeight="bold" fontSize="xl">{postDetails.comments_count || 0}</Text>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">シェア数</Text>
                                        <Text fontWeight="bold" fontSize="xl">0</Text>
                                    </Box>
                                    <Box textAlign={"center"}>
                                        <Text fontSize="lg">保存数</Text>
                                        <Text fontWeight="bold" fontSize="xl">{postDetails.saved || 0}</Text>
                                    </Box>
                                </HStack>
                            </VStack>
                        </CardBody>
                    </Card>
                </GridItem>

                {/* 右側: Instagram投稿プレビュー */}
                <GridItem>
                    <Card border="1px solid #dbdbdb" borderRadius="8px" bg="white" overflow="hidden">
                        {/* Instagram Header */}
                        <CardHeader
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}
                            borderBottom="1px solid #dbdbdb"
                            bg="white"
                        >
                            <HStack spacing={3}>
                                <Box
                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    bg="gray.300"
                                    overflow="hidden"
                                >
                                    <Image
                                        src={postDetails.profile_picture_url || 'https://via.placeholder.com/40'}
                                        alt="Profile"
                                        objectFit="cover"
                                    />
                                </Box>
                                <Text fontWeight="bold" fontSize="md">
                                    {postDetails.username || 'Instagram User'}
                                </Text>
                            </HStack>
                        </CardHeader>

                        {/* Instagram Style Image */}
                        <CardBody p={0}>
                            <Box position="relative" pb="100%" bg="black">
                                <Image
                                    src={postDetails.media_url}
                                    alt={postDetails.caption}
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                />
                            </Box>
                        </CardBody>

                        {/* Instagram Caption */}
                        <Box p={4}>
                            <Text fontSize="sm" mb={2}>
                                <strong>{postDetails.username || 'Instagram User'}</strong> {postDetails.caption}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                                {new Date(postDetails.timestamp).toLocaleDateString('ja-JP')}
                            </Text>
                        </Box>

                        {/* Instagram Footer */}
                        <Box textAlign="center" p={4} borderTop="1px solid #dbdbdb">
                            <a href={`https://www.instagram.com/p/${contentId}`} target="_blank" rel="noopener noreferrer">
                                <Button colorScheme="blue" size="sm">
                                    投稿をInstagramで見る
                                </Button>
                            </a>
                        </Box>
                    </Card>
                </GridItem>
            </Grid>
        </Box>
    );
};

export default InstagramContentsDetail;