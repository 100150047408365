import { memo } from "react"
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormLabel, Skeleton, Switch, VStack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { ErrorMessage } from "../../molecules/form/ErrorMessage";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useEditManlaboSettingFormForRestaurant } from "../../../hooks/manzokudoLabo/useEditManlaboSettingFormForRestaurant";
import { useEditManLaboSettingByRestaurant } from "../../../hooks/manzokudoLabo/useEditManLaboSettingByRestaurant";
import { useFetchManlaboSurveySelectedQuestionsByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManlaboSurveySelectedQuestionsByRestaurantId";
import { SelectBranchScore } from "../../molecules/form/SelectBranchScore";
import { QrCardImgInput } from "../../molecules/form/QrCardImgInput";
import { useFetchManLaboSettingByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManLaboSettingByRestaurantId";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import {UniqueQuestion} from "../../molecules/form/UniqueQuestion";


export const EditManlaboSettingByRestaurant = () => {
  const { restaurantId } = useParams()
  const {manLaboSetting, isLoading} = useFetchManLaboSettingByRestaurantId(restaurantId);
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useEditManlaboSettingFormForRestaurant(manLaboSetting)
  const onSubmit = useEditManLaboSettingByRestaurant(manLaboSetting?.id)
  if (!restaurantId) return null
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='満足度ラボ設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} isLoading={isLoading} />
              <SelectBranchQuestion restaurantId={restaurantId} control={control} errors={errors} />
              <FormControl>
                <FormLabel>レビューへの遷移</FormLabel>
                <Controller
                  name='is_navigate_g_url'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      size='md'
                      mb='0'
                      isChecked={value}
                      onChange={onChange}
                      colorScheme="messenger"
                    />
                  )}
                />
              </FormControl>
              {/*<UniqueQuestion control={control} errors={errors} isLoading={isLoading}/>*/}
              <QrCardImgInput qrCardImg={manLaboSetting?.qr_card_img} register={register} setValue={setValue} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const SelectBranchQuestion : React.FC<{restaurantId: string, control: any, errors: any}> = memo(({restaurantId, control, errors}) => {
  const { selectedQuestions, isLoading } = useFetchManlaboSurveySelectedQuestionsByRestaurantId(restaurantId);
  const selectedQuestionsOption = selectedQuestions?.map(selectedQuestion => ({
    label: selectedQuestion.question_content,
    value: selectedQuestion.question
  }));
  const branchQuestionError = errors.branch_question?.message;
  return (
    <FormControl isInvalid={!!errors.branch_question} isRequired w='100%'>
      <FormLabel>ブランチクエッション</FormLabel>
      <Skeleton isLoaded={!isLoading } w='100%' borderRadius={5}>
        <Controller
          name='branch_question'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && '質問が選択されていません'}
              placeholder="選択"
              options={selectedQuestionsOption}
              value={selectedQuestionsOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Skeleton>
      {branchQuestionError && <ErrorMessage errorMessage={branchQuestionError} />}
    </FormControl>
  );
})