import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchKuchikomyuQrCount } from "../../../hooks/kuchikomyu/useFetchKuchikomyuQrCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { RiSurveyLine } from "react-icons/ri";


type KuchikomyuOverallScoreAnswerCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const KuchikomyuQrCountCard: React.FC<KuchikomyuOverallScoreAnswerCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { KuchikomyuQrCount, isLoading } = useFetchKuchikomyuQrCount(restaurantId, startDate, endDate);
  console.log(KuchikomyuQrCount)
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${KuchikomyuQrCount}人`} title="QRコード読み込み人数">
        <RiSurveyLine size='30px' />
      </SmallCardWithIcon>
    </Skeleton>
  );
});