import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type LatestValidCoupons = {
  id: string;
  img: string | null;
  title: string;
}[]

export const useFetchLatestValidCoupons = (restaurantId: string, lineId: string) => {
  const { data: latestValidCoupons, error, isLoading } = useSwrNoAuth<LatestValidCoupons>(`membership/restaurants/${restaurantId}/line-accounts/${lineId}/latest-valid-coupons/`)
  console.log(restaurantId)
  console.log(lineId)
  return { latestValidCoupons, isLoading, error };
};