import React, { useState, useEffect } from 'react';
import {
    Box,
    HStack,
    Text,
    Button,
    Input,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    Spinner,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Image,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { FaEye, FaHeart, FaComment, FaShare, FaInstagram,FaHandshake,FaUserPlus,FaReply,FaSave,FaPlay } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { customAuthAxios } from '../../../utils/customAuthAxios';
import { getDjangoToken } from '../../../utils/instagram/services/apiService';

type MediaPost = {
    id: string;
    media_type: string;
    media_url: string;
    timestamp: string;
    permalink: string;
    caption?: string;
    impressions?: number;
    like_count?: number;
    comments_count?: number;
    saved?: number;
    reach?: number;
    profile_picture_url?: string;
    username?: string;
    plays?: number; // 動画再生回数
    shares?: number; // シェア数
    replies?: number; // 返信数
    follows?: number; // フォロー数
    total_interactions?: number; // 総インタラクション数
};

const truncateCaption = (caption: string | undefined, length: number = 100): string => {
    if (!caption) return 'No caption';
    return caption.length > length ? `${caption.substring(0, length)}...` : caption;
};

const InstagramContents: React.FC = () => {
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const [mediaData, setMediaData] = useState<MediaPost[]>([]);
    const [sortedMediaData, setSortedMediaData] = useState<MediaPost[]>([]);
    const [displayMode, setDisplayMode] = useState<'images' | 'videos'>('images');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<MediaPost | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);

    useEffect(() => {
        if (restaurantId) fetchContentData(restaurantId);
    }, [restaurantId]);

    useEffect(() => {
        const filteredData = mediaData.filter((post) => {
            const type = post.media_type?.toUpperCase();
            const postDate = new Date(post.timestamp);
            const isWithinDateRange =
                (!startDate || postDate >= new Date(startDate)) &&
                (!endDate || postDate <= new Date(endDate));
            return isWithinDateRange && (displayMode === 'images' ? type === 'IMAGE' : type === 'VIDEO');
        });
        setSortedMediaData(filteredData);
    }, [mediaData, displayMode, startDate, endDate]);

    const fetchContentData = async (restaurantId: string) => {
        try {
            setIsLoading(true);
            const djangoToken = await getDjangoToken();
            const response = await customAuthAxios.get(
                `external_api/instagram-auth/metrics/${restaurantId}/`,
                {
                    headers: { Authorization: `JWT ${djangoToken}` },
                }
            );
            setMediaData(response.data.metrics);
        } catch (error) {
            console.error('Error fetching Instagram content:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPostDetails = async (postId: string) => {
        if (!restaurantId) return;
        try {
            setIsDetailLoading(true);
            const djangoToken = await getDjangoToken();
            const response = await customAuthAxios.get(
                `/external_api/instagram-auth/metrics/${restaurantId}/${postId}/`,
                {
                    headers: { Authorization: `JWT ${djangoToken}` },
                }
            );
            setSelectedPost(response.data);
        } catch (error) {
            console.error('Failed to fetch post details:', error);
        } finally {
            setIsDetailLoading(false);
        }
    };

    // const openModal = (postId: string) => {
    //     fetchPostDetails(postId);
    //     setIsModalOpen(true);
    // };
    //
    // const closeModal = () => {
    //     setIsModalOpen(false);
    //     setSelectedPost(null);
    // };

    const openModal = (postId: string) => {
        const post = mediaData.find((item) => item.id === postId); // クリックされた投稿を取得
        if (post) {
            setSelectedPost(post); // モーダル用の詳細データを設定
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPost(null);
    };

    return (
        <Box p={4}>
            <Card>
                <CardHeader>
                    <HStack spacing={1} alignItems="center">
                        <FaInstagram />
                        <Text fontWeight="bold" fontSize="xl">リーチ別のトップコンテンツ</Text>
                    </HStack>
                    <HStack spacing={4} mt={4}>
                        <Input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="開始日"
                        />
                        <Input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="終了日"
                        />
                        <Button onClick={() => { setStartDate(''); setEndDate(''); }}>リセット</Button>
                    </HStack>
                    <HStack spacing={4} mt={4}>
                        <Button
                            onClick={() => setDisplayMode('images')}
                            colorScheme={displayMode === 'images' ? 'blue' : 'gray'}
                        >
                            画像
                        </Button>
                        <Button
                            onClick={() => setDisplayMode('videos')}
                            colorScheme={displayMode === 'videos' ? 'blue' : 'gray'}
                        >
                            動画
                        </Button>
                    </HStack>
                </CardHeader>

                <CardBody>
                    {isLoading ? (
                        <Center h="200px">
                            <Spinner size="xl" color="blue.500" />
                        </Center>
                    ) : (
                        <SimpleGrid columns={[2, 3, 4]} spacing={4}>
                            {sortedMediaData.map((post) => (
                                <Box
                                    key={post.id}
                                    p={4}
                                    bg="white"
                                    borderRadius="md"
                                    boxShadow="md"
                                    cursor="pointer"
                                    onClick={() => openModal(post.id)} // モーダルを開く
                                >
                                    {/* 動画または画像を表示 */}
                                    {post.media_type === "VIDEO" || post.media_type === "REEL" ? (
                                        <video controls width="100%">
                                            <source src={post.media_url} type="video/mp4" />
                                            お使いのブラウザは video タグをサポートしていません。
                                        </video>
                                    ) : (
                                        <Image
                                            src={post.media_url}
                                            alt={post.caption || 'No caption'}
                                            width="100%"
                                        />
                                    )}

                                    {/* キャプションをトリミングして表示 */}
                                    <Text mt={2}>{truncateCaption(post.caption)}</Text>

                                    {/* インプレッションやいいね、コメントを表示 */}
                                    <HStack mt={2} justifyContent="space-between">
                                        <Box textAlign="center">
                                            <FaEye />
                                            <Text fontSize="sm">{post.impressions || 0}</Text>
                                        </Box>
                                        <Box textAlign="center">
                                            <FaHeart />
                                            <Text fontSize="sm">{post.like_count || 0}</Text>
                                        </Box>
                                        <Box textAlign="center">
                                            <FaComment />
                                            <Text fontSize="sm">{post.comments_count || 0}</Text>
                                        </Box>
                                    </HStack>
                                </Box>
                            ))}
                        </SimpleGrid>
                    )}
                </CardBody>
            </Card>

            <Modal isOpen={isModalOpen} onClose={closeModal} size="3xl">
                <ModalOverlay />
                <ModalContent maxWidth="60vw" p={4}>
                    <ModalHeader>投稿の詳細</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedPost ? (
                            <Grid templateColumns="2fr 2fr" gap={4}>
                                {/* 左側: 詳細情報 */}
                                <GridItem>
                                    <Card>
                                        <CardHeader>
                                            <Text fontWeight="bold" fontSize="lg">概要</Text>
                                        </CardHeader>
                                        <CardBody>
                                            <VStack spacing={4} align="start">
                                                {selectedPost.media_type === "IMAGE" || selectedPost.media_type === "CAROUSEL_ALBUM" ? (
                                                    <>
                                                        <HStack>
                                                            <FaEye />
                                                            <Text>リーチ: {selectedPost.reach || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaEye />
                                                            <Text>インプレッション: {selectedPost.impressions || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaHeart />
                                                            <Text>いいね: {selectedPost.like_count || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaComment />
                                                            <Text>コメント: {selectedPost.comments_count || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaSave />
                                                            <Text>保存数: {selectedPost.saved || 0}</Text>
                                                        </HStack>
                                                    </>
                                                ) : selectedPost.media_type === "VIDEO" || selectedPost.media_type === "REEL" ? (
                                                    <>
                                                        <HStack>
                                                            <FaEye />
                                                            <Text>リーチ: {selectedPost.reach || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaPlay />
                                                            <Text>再生回数: {selectedPost.plays || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaHeart />
                                                            <Text>いいね: {selectedPost.like_count || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaComment />
                                                            <Text>コメント: {selectedPost.comments_count || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaSave />
                                                            <Text>保存数: {selectedPost.saved || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaShare />
                                                            <Text>シェア数: {selectedPost.shares || 0}</Text>
                                                        </HStack>
                                                    </>
                                                ) : selectedPost.media_type === "STORY" ? (
                                                    <>
                                                        <HStack>
                                                            <FaEye />
                                                            <Text>リーチ: {selectedPost.reach || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaEye />
                                                            <Text>インプレッション: {selectedPost.impressions || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaReply />
                                                            <Text>返信数: {selectedPost.replies || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaUserPlus />
                                                            <Text>フォロー数: {selectedPost.follows || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaShare />
                                                            <Text>シェア数: {selectedPost.shares || 0}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <FaHandshake />
                                                            <Text>総インタラクション数: {selectedPost.total_interactions || 0}</Text>
                                                        </HStack>
                                                    </>
                                                ) : (
                                                    <Text>サポートされていないメディアタイプです。</Text>
                                                )}
                                            </VStack>
                                        </CardBody>
                                    </Card>
                                </GridItem>

                                {/* 右側: Instagram投稿プレビュー */}
                                <GridItem>
                                    <Card>
                                        <CardBody>
                                            {/* 動画または画像をメディアタイプに応じて表示 */}
                                            {selectedPost.media_type === "VIDEO" || selectedPost.media_type === "REEL" ? (
                                                <video controls width="100%">
                                                    <source src={selectedPost.media_url} type="video/mp4" />
                                                    お使いのブラウザは video タグをサポートしていません。
                                                </video>
                                            ) : (
                                                <Image
                                                    src={selectedPost.media_url}
                                                    alt={selectedPost.caption || "No caption"}
                                                    width="100%"
                                                />
                                            )}
                                            {/* トリミングされたキャプションを表示 */}
                                            <Text mt={2}>{truncateCaption(selectedPost.caption, 200)}</Text>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </Grid>
                        ) : (
                            <Text>データが見つかりません。</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={closeModal}>閉じる</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default InstagramContents;