import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type KuchikomyuSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  en_url: string;
  unique_question: boolean;
}

export const useFetchKuchikomyuSettingCustomerByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `kuchikomyu/restaurants/${restaurantId}/setting/customer/` : null;
  const { data: kuchikomyuSetting, error, isLoading } = useSwrNoAuth<KuchikomyuSetting>(fetcherKey)
  return { kuchikomyuSetting, isLoading, error };
};