import React from 'react';
import { FormControl, FormErrorMessage, Checkbox } from '@chakra-ui/react';
import { Controller, Control, FieldErrors,FieldError } from 'react-hook-form';

type UniqueQuestionProps = {
    control: Control<any>;  // `control` を型として定義
    errors: FieldErrors<any>;
    isLoading?: boolean;
};

const getErrorMessage = (error: unknown): string => {
    console.log("Error object:", error);
    if (typeof error === 'string') return error; // 文字列ならそのまま
    if (error && typeof error === 'object' && 'message' in error) {
        return String((error as FieldError).message); // `FieldError` 型なら `message` を取得
    }
    return ''; // それ以外なら空文字
};

export const UniqueQuestion: React.FC<UniqueQuestionProps> = ({ control, errors, isLoading }) => {
    console.log("UniqueQuestion props:", { control, errors, isLoading });
    return (
        <FormControl isInvalid={!!errors.unique_question}>
            <Controller
                name="unique_question"
                control={control}
                render={({ field }) => {
                    console.log("Checkbox value:", field.value); // 🛠 チェックボックスの状態を確認
                    return (
                        <Checkbox
                            isChecked={field.value}
                            onChange={field.onChange}
                            isDisabled={isLoading}
                        >
                            独自アンケートを使用しない
                        </Checkbox>
                    );
                }}
            />
            <FormErrorMessage>{getErrorMessage(errors.unique_question)}</FormErrorMessage>
        </FormControl>
    );
};