import axios from 'axios';
import {customAuthAxios} from "../../customAuthAxios";

export const fetchProfileMetrics = async (accessToken: string, businessAccountId: string) => {
    const response = await axios.get(`https://graph.facebook.com/v21.0/${businessAccountId}`, {
        params: { fields: 'followers_count,follows_count', access_token: accessToken },
    });
    return {
        followerCount: response.data.followers_count || 0,
        followingCount: response.data.follows_count || 0,
    };
};

export const fetchMediaData = async (accessToken: string, businessAccountId: string) => {
    const response = await axios.get(`https://graph.facebook.com/v21.0/${businessAccountId}/media`, {
        params: {
            fields: 'id,caption,media_type,media_url,permalink,timestamp',
            access_token: accessToken,
        },
    });

    const mediaData = response.data.data;

    const mediaWithInsights = await Promise.all(
        mediaData.map(async (post: any) => {
            // メディアタイプに応じてメトリクスを設定
            let metrics = '';
            if (post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM') {
                metrics = 'impressions,reach,likes,comments,saved';
            } else if (post.media_type === 'VIDEO' || post.media_type === 'REEL') {
                metrics = 'reach,likes,comments,saved,plays';
            } else if (post.media_type === 'STORY') {
                metrics = 'impressions,reach,replies,saved,exits';
            } else {
                console.warn(`Unsupported media type: ${post.media_type}`);
                return post; // サポートされていないメディアタイプの場合はインサイトを取得しない
            }

            try {
                const insightsResponse = await axios.get(`https://graph.facebook.com/v21.0/${post.id}/insights`, {
                    params: {
                        metric: metrics,
                        access_token: accessToken,
                    },
                });

                const insights = insightsResponse.data.data.reduce((acc: any, insight: any) => {
                    acc[insight.name] = insight.values[0].value;
                    return acc;
                }, {});

                // console.log("Fetched insights for post:", post.id, insights); // 取得したインサイトデータを表示

                return {
                    ...post,
                    ...insights,
                };
            } catch (error) {
                console.error("Error fetching insights for post:", post.id, error);
                return post; // インサイトが取得できない場合は基本情報のみ返す
            }
        })
    );

    return mediaWithInsights;
};

// export const fetchMediaData = async (accessToken: string, businessAccountId: string) => {
//     const response = await axios.get(`https://graph.facebook.com/v21.0/${businessAccountId}/media`, {
//         params: { fields: 'id,caption,media_type,media_url,permalink,timestamp', access_token: accessToken },
//     });
//     return response.data.data;
// };

export const saveAuthData = async (restaurantId: string, accessToken: string, businessAccountId: string, djangoToken: string) => {
    await customAuthAxios.post(
        // `http://localhost:8000/external_api/instagram-auth/${restaurantId}/`,
        `external_api/instagram-auth/${restaurantId}/`,
        { token: accessToken, instagram_id: businessAccountId },
        { headers: { Authorization: `JWT ${djangoToken}` } }
    );
};

export const getDjangoToken = async () => {
    try {
        const response = await customAuthAxios.post(
            "authen/jwt/create/", {
        //const response = await axios.post("authen/jwt/create/", {
            username: process.env.REACT_APP_DJANGO_USERNAME,
            password: process.env.REACT_APP_DJANGO_PASSWORD,
        });
        return response.data.access;  // トークンを返す
    } catch (error) {
        console.error("Error fetching Django JWT token:", error);
        return null;
    }
};