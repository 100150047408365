import {memo, useEffect, useState} from "react"
import {
    Box,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Text,
    VStack
} from "@chakra-ui/react";
import {useEditKuchikomyuSettingByRestaurant} from "../../../hooks/kuchikomyu/useEditKuchikomyuSettingByRestaurant";
import {SubmitBtn} from "../../atoms/button/SubmitBtn";
import {
    useEditKuchikomyuSettingFormForRestaurant
} from "../../../hooks/kuchikomyu/useEditKuchikomyuSettingFormForRestaurant";
import {SelectBranchScore} from "../../molecules/form/SelectBranchScore";
import {QrCardImgInput} from "../../molecules/form/QrCardImgInput";
import {
    useFetchKuchikomyuSettingByRestaurantId
} from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingByRestaurantId";
import {CircularLoading} from "../../molecules/loading/CircularLoading";
import {useParams} from "react-router-dom";
import {DarkShadowCard} from "../../molecules/card/DarkShadowCard";
import {CardHeading} from "../../molecules/card/CardHeading";
import {SettingsIcon} from "@chakra-ui/icons";
import {UniqueQuestion} from "../../molecules/form/UniqueQuestion";


export const EditKuchikomyuSettingByRestaurant = () => {
    const {restaurantId} = useParams()
    const {kuchikomyuSetting, isLoading} = useFetchKuchikomyuSettingByRestaurantId(restaurantId);
    const {
        handleSubmit,
        register,
        control,
        isSubmitting,
        errors,
        setValue
    } = useEditKuchikomyuSettingFormForRestaurant(kuchikomyuSetting)
    const onSubmit = useEditKuchikomyuSettingByRestaurant(kuchikomyuSetting?.id)
    // const onSubmit = (data: any) => {
    //     console.log("フォーム送信データ:", data);
    //     console.log("送信する unique_question の値:", data.unique_question);
    //     console.log("エラー情報:", errors);
    // };
    if (isLoading) return <CircularLoading/>
    return (
        <Box m='auto' w={{base: '100%', md: '580px'}}>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <DarkShadowCard>
                    <CardHeader>
                        <CardHeading icon={<SettingsIcon/>} heading='口コミュ設定'/>
                    </CardHeader>
                    <CardBody py={2}>
                        <VStack spacing={5}>
                            <SelectBranchScore control={control} errors={errors} isLoading={isLoading}/>
                            <UniqueQuestion control={control} errors={errors} isLoading={isLoading}/>
                            <QrCardImgInput qrCardImg={kuchikomyuSetting?.qr_card_img} register={register}
                                            setValue={setValue}/>
                            <EnQrCardImgInput qrCardImg={kuchikomyuSetting?.en_qr_card_img} register={register}
                                              setValue={setValue}/>
                        </VStack>
                    </CardBody>
                    <CardFooter>
                        <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
                    </CardFooter>
                </DarkShadowCard>
            </form>
        </Box>
    );
};


type QrCardImgInputProps = {
    qrCardImg?: string;
    register: any;
    setValue: any;
}
const EnQrCardImgInput: React.FC<QrCardImgInputProps> = memo(({qrCardImg, register, setValue}) => {
    const [useDefaultImg, setUseDefaultImg] = useState<boolean>(qrCardImg ? false : true);
    useEffect(() => {
        if (useDefaultImg) setValue('en_qr_card_img', 'default');
        if (!useDefaultImg) setValue('en_qr_card_img', '');
    }, [useDefaultImg]);
    useEffect(() => {
        setUseDefaultImg(qrCardImg ? false : true)
    }, [qrCardImg]);
    return (
        <FormControl>
            <FormLabel>英語版QRカード</FormLabel>
            <VStack align='start' spacing={1}>
                {qrCardImg &&
                    <Box>
                        <Text m='0' fontSize='xs' color='gray.500'>現在の画像</Text>
                        <Box
                            bgImage={`url(${qrCardImg})`}
                            w="210px"
                            h="297px"
                            bgSize="contain"
                            bgRepeat="no-repeat"
                            border='0.1px solid #c0c0c09d'
                        />
                    </Box>
                }
                <input type="file" accept="image/*" {...register('en_qr_card_img')} disabled={useDefaultImg}/>
                <Checkbox isChecked={useDefaultImg} onChange={() => setUseDefaultImg(!useDefaultImg)} size='sm'
                          m='0'>デフォルト画像を使用</Checkbox>
            </VStack>
            <FormHelperText>縦横比 1:√2</FormHelperText>
        </FormControl>
    )
})