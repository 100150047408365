import useSWR from "swr";

type KuchikomyuSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  en_url: string;
  qr_card_img?: string;
  en_qr_card_img?: string;
  unique_question: boolean;
}

export const useFetchKuchikomyuSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `kuchikomyu/settings/${settingsId}` : null;
  const { data: kuchikomyuSetting, error, isLoading } = useSWR<KuchikomyuSetting>(fetcherKey)
  return { kuchikomyuSetting, isLoading, error };
};