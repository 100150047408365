import useSWR from "swr";


type ManLaboSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
  branch_question: string;
  branch_question_name: string;
  qr_card_img?: string;
  unique_question: boolean;
}
export const useFetchManLaboSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `manzokudo_labo/restaurants/${restaurantId}/setting/` : null;
  const { data: manLaboSetting, error, isLoading } = useSWR<ManLaboSetting>(fetcherKey)
  return { manLaboSetting, isLoading, error };
};